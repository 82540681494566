module.exports = {
  data : {
    standard: [
      "300",
      "301",
      "302",
      "303",
      "304",
      "305",
      "306",
      "307",
      "308",
      "309",
      "30a",
      "30b",
      "30c",
      "30d",
      "30e",
      "30f",
      "310",
      "311",
      "312",
      "313",
      "314",
      "315",
      "316",
      "317",
      "318",
      "319",
      "31a",
      "31b",
      "31c",
      "31d",
      "31e",
      "31f",
      "320",
      "321",
      "322",
      "323",
      "324",
      "325",
      "326",
      "327",
      "328",
      "329",
      "32a",
      "32b",
      "32c",
      "32d",
      "32e",
      "32f",
      "330",
      "331",
      "332",
      "333",
      "334",
      "335",
      "336",
      "337",
      "338",
      "339",
      "33a",
      "33b",
      "33c",
      "33d",
      "33e",
      "33f",
      "340",
      "341",
      "342",
      "343",
      "344",
      "345",
      "346",
      "347",
      "348",
      "349",
      "34a",
      "34b",
      "34c",
      "34d",
      "34e",
      "34f",
      "350",
      "351",
      "352",
      "353",
      "354",
      "355",
      "356",
      "357",
      "358",
      "359",
      "35a",
      "35b",
      "35c",
      "35d",
      "35e",
      "35f",
      "360",
      "361",
      "362",
      "363",
      "364",
      "365",
      "366",
      "367",
      "368",
      "369",
      "36a",
      "36b",
      "36c",
      "36d",
      "36e",
      "36f"
    ]
  },
  
}